<ngx-slick-carousel id="slide-main" class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
    (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
    <div ngxSlickItem *ngFor="let slide of slides; let i = index" class="slide">
        <h1 class="sr-only"><span>Sorteo Anáhuac 9ª Edición</span></h1>
        <section id="slider-awards" style="background-image: url( {{slide.img}} );">
            <div class="container">
                <div class="row">

                    <div class="col-md-6 my-auto">

                        <div class="content-title title-slide text-center img-so" *ngIf="slide.number == 1">
                            <!-- <img src="/assets/images/sorteo2024/{{ slide.imdAw }}" class="img-fluid mx-auto d-md-block d-none"   data-aos="zoom-in" data-aos-duration="2000" > -->
                            <a routerLink="/ganadores-sorteos"  id="href1">
                                <img src="/assets/images/sorteo2024/{{ slide.text }}" class="img-fluid mx-auto "
                                    data-aos="zoom-in" data-aos-duration="2000">

                            </a>



                        </div>

                        <div class="content-title title-slide text-center" *ngIf="slide.number > 1">
                            <!-- <p> <strong> {{ slide.number }}</strong>/{{slides.length}}</p> -->
                            <p class="box">{{ slide.place }}</p>
                            <h2>{{ slide.title }}</h2>
                            <p>{{ slide.value }}</p>
                            <p class="last">
                                <i class="fa fa-map-marker fa-6" aria-hidden="true"></i>
                                {{ slide.name }}</p>

                            <a href="https://sorteoanahuaccompra.sorteoanahuac.mx/compra-en-linea-boletos-sorteo-anahuac{{urlUtm}}"
                                target="_blank" class="btn-stack">
                                Compra aquí
                            </a>
                        </div>




                    </div>

                    <div class="col-md-6 my-auto">

                        <!-- <a  *ngIf="slide.number == 1"  href="/lista-ganadores-primer-sorteo-oportuno-2024" target="_blank">
                                <img src="/assets/images/sorteo2024/{{ slide.imdAw }}" class="img-fluid mx-auto "   data-aos="zoom-in" data-aos-duration="2000" >
                            </a>
                            -->

                        <a *ngIf="slide.number == 1" routerLink="/ganadores-sorteos"
                             id="href2">
                            <img src="/assets/images/sorteo2024/{{ slide.imdAw }}" class="img-fluid mx-auto img-so-text"
                                data-aos="zoom-in" data-aos-duration="2000">
                        </a>


                        <img src="/assets/images/sorteo2024/{{ slide.imdAw }}" class="img-fluid mx-auto"
                            data-aos="zoom-in" data-aos-duration="2000" *ngIf="slide.number != 1">
                    </div>


                </div>
            </div>
        </section>


    </div>
</ngx-slick-carousel>

<section id="lucky">
    <div class="container">
        <div class="row">

            <div class="col-lg-6 col-md-12">
                <h3>
                    Máquina
                    <span class="d-md-block">
                        de la suerte
                    </span>
                </h3>
                <p>
                    Descubre tu número de la suerte
                </p>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="content-machine">
                    <div class="numbers btn-gradient">
                        <ul id="list-n0">
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>

                        </ul>
                    </div>
                    <div class="numbers btn-gradient">
                        <ul id="list-n1">
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>


                        </ul>
                    </div>
                    <div class="numbers btn-gradient">
                        <ul id="list-n2">
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>


                        </ul>
                    </div>

                    <div class="numbers btn-gradient">
                        <ul id="list-n3">
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>


                        </ul>
                    </div>

                    <div class="numbers btn-gradient">
                        <ul id="list-n4">
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>


                        </ul>
                    </div>

                    <div class="numbers btn-gradient">
                        <ul id="list-n5">
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                            <li>0</li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>


                        </ul>
                    </div>

                </div>

                <div class="buttons-generate text-center mt-3">
                    <a *ngIf="!flag_buy" (click)="getBoletoRandom();" class="btn-stack generate-number  mx-auto">
                        <!-- <img src="/assets/images/sorteo2023/icon-a.png" class="img-fluid" > -->
                        ¡Activa tu suerte aquí!
                    </a>
                    <div *ngIf="flag_buy_loader" class="w-100">
                        <p>
                            Generando tu número...
                        </p>
                        <div class="lds-hourglass"></div>

                    </div>

                    <a *ngIf="flag_buy_btn" (click)="sendTicket();" class="btn-stack  mx-auto">
                        Comprar
                    </a>
                </div>
            </div>

        </div>
    </div>
</section>

<section id="counters-box-cta">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1 text-center">
                <h2>Próximos sorteos</h2>
                <p>
                    Estamos cerca a celebrar nuestros sorteos y de conocer a los afortunado ganadores. Uno de ellos
                    podría ser tú.
                </p>
            </div>

            <div class="col-lg-4">
                <a [routerLink]="['/sorteo-oportuno']">
                    <div class="content-box text-center" style="background-color: #ff9b00;">
                        <div class="image-text-box" style="background-color: #662380;">
                            <div class="row">
                                <div class="col-md-5 my-auto pr-lg-0">
                                    <img src="/assets/images/sorteo2024/icon-so3.png" alt="Primer Sorteo Oportuno"
                                        class="img-fluid">
                                </div>
                                <div class="col-md-7  my-auto pl-lg-0">
                                    <span>Sorteo <span class="d-md-block">oportuno</span></span>
                                </div>
                            </div>

                        </div>
                        <div class="counter-box" *ngIf="!flagShow">
                            <span class="content-day">
                                <span class="day">
                                    {{ dias }}
                                </span>
                                <span class="text d-block">
                                    Dias
                                </span>
                            </span>
                            <span class="point">.</span>

                            <span class="content-day">
                                <span class="hours">
                                    {{ horas }}
                                </span>
                                <span class="text d-block">
                                    hrs
                                </span>
                            </span>
                            <span class="point">.</span>

                            <span class="content-day">
                                <span class="minutes">
                                    {{ minutos }}
                                </span>
                                <span class="text d-block">
                                    min
                                </span>
                            </span>
                            <span class="point">.</span>

                            <span class="content-day">
                                <span class="seconds">
                                    {{ segundos }}
                                </span>
                                <span class="text d-block">
                                    seg
                                </span>
                            </span>
                        </div>

                        <a href="https://linktr.ee/sorteoanahuac.redesorganico" class="btn-card" target="_blank" *ngIf="flagShow">
                            ¡ESTAMOS EN VIVO! 
                        </a>

                        <!-- <div class="btn-live">
                        <a class="btn-card" href="https://www.facebook.com/events/426072950312806/" target="_blank"> ¡Estamos en vivo! </a>
                    </div> -->
                    </div>
                </a>
            </div>

            <div class="col-lg-4">
                <!-- <a routerLink="/lista-ganadores-segundo-sorteo-oportuno-2024"> -->
                    <div class="content-box text-center" style="background-color: #6e5dff;">
                        <div class="image-text-box" style="background-color: #462cff;">
                            <div class="row">
                                <div class="col-md-5  my-auto pr-lg-0">
                                    <img src="/assets/images/sorteo2024/hat.png" alt="Sorteo Egresados"
                                        class="img-fluid">
                                </div>
                                <div class="col-md-7  my-auto pl-lg-0">
                                    <span>
                                        Sorteo
                                        <span class="d-md-block">EGRESADOS</span>
                                    </span>
                                </div>
                            </div>

                        </div>
                        <div class="counter-box" *ngIf="!flagShow2">
                            <span class="content-day">
                                <span class="day">
                                    {{ dias_egr }}
                                </span>
                                <span class="text d-block">
                                    Dias
                                </span>
                            </span>
                            <span class="point">.</span>

                            <span class="content-day">
                                <span class="hours">
                                    {{ horas_egr }}
                                </span>
                                <span class="text d-block">
                                    hrs
                                </span>
                            </span>
                            <span class="point">.</span>

                            <span class="content-day">
                                <span class="minutes">
                                    {{ minutos_egr }}
                                </span>
                                <span class="text d-block">
                                    min
                                </span>
                            </span>
                            <span class="point">.</span>

                            <span class="content-day">
                                <span class="seconds">
                                    {{ segundos_egr }}
                                </span>
                                <span class="text d-block">
                                    seg
                                </span>
                            </span>
                        </div>

                        <a href="https://www.youtube.com/@sorteoanahuac_oficial" target="_blank" class="btn-card"  *ngIf="flagShow2">
                            ¡REVIVE EL EN VIVO! 
                        </a>
                    </div>
                <!-- </a> -->
            </div>

            <div class="col-lg-4">
                <a href="https://sorteoanahuaccompra.sorteoanahuac.mx" target="_blank">
                    <div class="content-box text-center" style="background-color: #662380;">
                        <div class="image-text-box" style="background-color: #ff5900;">
                            <div class="row">
                                <div class="col-md-5  my-auto pr-lg-0">
                                    <img src="/assets/images/sorteo2024/sa_pelota.png" alt="Sorteo Magno"
                                        class="img-fluid" width="80">
                                </div>
                                <div class="col-md-7  my-auto pl-lg-0">
                                    <span>
                                        Sorteo
                                        <span class="d-md-block">MAGNO</span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="counter-box">
                            <span class="content-day">
                                <span class="day">
                                    {{ day }}
                                </span>
                                <span class="text d-block">
                                    Dias
                                </span>
                            </span>
                            <span class="point">.</span>

                            <span class="content-day">
                                <span class="hours">
                                    {{ hours }}
                                </span>
                                <span class="text d-block">
                                    hrs
                                </span>
                            </span>
                            <span class="point">.</span>

                            <span class="content-day">
                                <span class="minutes">
                                    {{ minutes }}
                                </span>
                                <span class="text d-block">
                                    min
                                </span>
                            </span>
                            <span class="point">.</span>

                            <span class="content-day">
                                <span class="seconds">
                                    {{ seconds }}
                                </span>
                                <span class="text d-block">
                                    seg
                                </span>
                            </span>
                        </div>
                    </div>
                </a>

            </div>


        </div>
    </div>
</section>

<section id="sorteos">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2>
                    Conoce Nuestros Sorteos
                </h2>
            </div>

            <div class="col-lg-3 offset-lg-2">
                <div class="card w-100">
                    <div class="image-text-box">
                        <div class="row">
                            <div class="col-md-4 my-auto ">
                                <img src="/assets/images/sorteo2024/icon-so1.png" alt="Primer Sorteo Oportuno"
                                    class="img-fluid">
                            </div>
                            <div class="col-md-8  my-auto pl-lg-0">
                                <span>Sorteo <span class="d-md-block">oportuno</span></span>
                            </div>
                        </div>

                    </div>
                    <div class="card-body">
                        <small>Primer premio</small>
                        <p>
                            CHEQUE CERTIFICADO DE
                            <span class="d-block">
                                $250,000.00
                            </span>
                        </p>
                        <div class="text-center">
                            <a href="/lista-ganadores-primer-sorteo-oportuno-2024" target="_blank" class="btn-card">
                                !LISTA DE GANADORES!
                            </a>
                        </div>

                        <small class="d-block">24 de mayo 2024</small>
                        <p>
                            11:15 hrs. <br>
                            Prepa Anáhuac México
                        </p>
                        <small class="d-block">
                            Campus Oxford
                        </small>
                        <div class="text-center">
                            <a href="https://www.youtube.com/watch?v=mYhunKy6Fa0&ab_channel=SorteoAn%C3%A1huac"
                                target="_blank" class="btn-card">
                                ¡REVIVE EL EN VIVO!
                            </a>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-3">
                <div class="card card-a2 w-100">
                    <div class="image-text-box">
                        <div class="row">
                            <div class="col-md-4 my-auto ">
                                <img src="/assets/images/sorteo2024/icon-so2.png" alt="Primer Sorteo Oportuno"
                                    class="img-fluid">
                            </div>
                            <div class="col-md-8  my-auto pl-lg-0">
                                <span>Sorteo <span class="d-md-block">oportuno</span></span>
                            </div>
                        </div>

                    </div>
                    <div class="card-body">
                        <small>Primer premio</small>
                        <p>
                            CHEQUE CERTIFICADO DE
                            <span class="d-block">
                                $250,000.00
                            </span>
                        </p>
                        <div class="text-center">
                            <a [routerLink]="['/lista-participantes-segundo-sorteo-oportuno-2024']" class="btn-card" target="_blank">
                                LISTA DE PARTICIPANTES
                            </a>
                        </div>

                        <small class="d-block">23 de agosto 2024</small>
                        <p>
                            11:15 hrs. <br>
                            Prepa Anáhuac México
                        </p>
                        <small class="d-block">
                            Campus Cumbres
                        </small>
                        <div class="text-center">
                            <a href="https://www.youtube.com/live/bmrGWDhUMqw" target="_blank" class="btn-card">
                                ¡REVIVE EL EN VIVO! 
                            </a>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-3">
                <div class="card card-a3 w-100">
                    <div class="image-text-box">
                        <div class="row">
                            <div class="col-md-4 my-auto ">
                                <img src="/assets/images/sorteo2024/icon-so3.png" alt="Primer Sorteo Oportuno"
                                    class="img-fluid">
                            </div>
                            <div class="col-md-8  my-auto pl-lg-0">
                                <span>Sorteo <span class="d-md-block">oportuno</span></span>
                            </div>
                        </div>

                    </div>
                    <div class="card-body">
                        <small>Primer premio</small>
                        <p>
                            CHEQUE CERTIFICADO DE
                            <span class="d-block">
                                $250,000.00
                            </span>
                        </p>
                        <div class="text-center">
                            <a [routerLink]="['/sorteo-oportuno']" class="btn-card">
                                CONOCE LOS PREMIOS
                            </a>
                        </div>

                        <small class="d-block">18 de octubre 2024</small>
                        <p>
                            11:15 hrs. <br>
                            Cumbres International
                        </p>
                        <small class="d-block">
                            School Lomas
                        </small>
                        <div class="text-center">
                            <a href="https://sorteoanahuaccompra.sorteoanahuac.mx" target="_blank" class="btn-card">
                                PARTICIPA
                            </a>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-3 offset-lg-2">
                <div class="card card-stack w-100">
                    <div class="image-text-box">
                        <div class="row">
                            <div class="col-md-5 my-auto ">
                                <img src="/assets/images/sorteo2024/hat.png" alt="Primer Sorteo Oportuno"
                                    class="img-fluid">
                            </div>
                            <div class="col-md-7  my-auto pl-lg-0">
                                <span>Sorteo <span class="d-md-block">Egresados</span></span>
                                <small>Anahúac 2024</small>
                            </div>
                        </div>

                    </div>
                    <div class="card-body">
                        <small>Primer premio</small>
                        <p>
                            CHEQUE CERTIFICADO DE
                            <span class="d-block">
                                $200,000.00
                            </span>
                        </p>


                        <small class="d-block mt-5 pt-2">23 de agosto 2024</small>
                        <p>
                            10:00 hrs. <br>
                            Prepa Anáhuac México
                        </p>
                        <small class="d-block">
                            Campus Cumbres
                        </small>
                        <div class="text-center">
                            <a routerLink="/lista-participantes-sorteo-egresados-anahuac-2024" target="_blank"
                                class="btn-card">
                                LISTA DE PARTICIPANTES
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3">
                <div class="card card-stack w-100">
                    <div class="image-text-box">
                        <div class="row">
                            <div class="col-md-5 my-auto ">
                                <img src="/assets/images/sorteo2024/hat.png" alt="Primer Sorteo Oportuno"
                                    class="img-fluid">
                            </div>
                            <div class="col-md-7  my-auto pl-lg-0">
                                <span>Sorteo <span class="d-md-block">Egresados</span></span>
                                <small>Lealtad 2024</small>
                            </div>
                        </div>

                    </div>
                    <div class="card-body">
                        <small>Primer premio</small>
                        <p>
                            CHEQUE CERTIFICADO DE
                            <span class="d-block">
                                $200,000.00
                            </span>
                        </p>


                        <small class="d-block mt-5 pt-2">23 de agosto 2024</small>
                        <p>
                            10:00 hrs. <br>
                            Prepa Anáhuac México
                        </p>
                        <small class="d-block">
                            Campus Cumbres
                        </small>
                        <div class="text-center">
                            <a routerLink="/lista-participantes-sorteo-egresados-lealtad-2024" target="_blank"
                                class="btn-card">
                                LISTA DE PARTICIPANTES
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3">
                <div class="card card-stack w-100">
                    <div class="image-text-box">
                        <div class="row">
                            <div class="col-md-5 my-auto ">
                                <img src="/assets/images/sorteo2024/hat.png" alt="Primer Sorteo Oportuno"
                                    class="img-fluid">
                            </div>
                            <div class="col-md-7  my-auto pl-lg-0">
                                <span>Sorteo <span class="d-md-block">Egresados</span></span>
                                <small>Amigo 2024</small>
                            </div>
                        </div>

                    </div>
                    <div class="card-body">
                        <small>Primer premio</small>
                        <p>
                            CHEQUE CERTIFICADO DE
                            <span class="d-block">
                                $50,000.00
                            </span>
                        </p>


                        <small class="d-block mt-5 pt-2">23 de agosto 2024</small>
                        <p>
                            10:00 hrs. <br>
                            Prepa Anáhuac México
                        </p>
                        <small class="d-block">
                            Campus Cumbres
                        </small>
                        <div class="text-center">
                            <a routerLink="/lista-participantes-sorteo-egresados-amigo-referido-2024" target="_blank"
                                class="btn-card">
                                LISTA DE PARTICIPANTES
                            </a>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-9 offset-lg-2">
                <div class="card card-main w-100">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6 my-auto">
                                <h5>
                                    SORTEO MAGNO
                                </h5>
                                <small>Primer premio</small>
                                <p>
                                    Departamento
                                    en Cancún
                                </p>
                                <span>
                                    Valor + de 10 millones de pesos
                                </span>
                                <small class="d-block mt-4">21 de noviembre 2024 | 17:00 hrs.</small>
                                <span class="d-block">IEST Anáhuac</span>

                                <a routerLink="/premios-sorteo-anahuac" class="btn-card">CONOCE LOS PREMIOS</a>
                                <a href="https://sorteoanahuaccompra.sorteoanahuac.mx" target="_blank"
                                    class="btn-card">PARTICIPA</a>
                            </div>
                            <div class="col-md-6  my-auto">
                                <img src="/assets/images/sorteo2024/card-award.png" alt="Primer Premio"
                                    class="img-fluid">
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</section>






<section id="btn-cta">
    <div class="container">
        <div class="row">
            <!-- <div class="col-md-6 "> 
                <div class="bg-cta ganadores">
                    <h4>
                        NUESTROS GANADORES 2022
                    </h4>
                    <a [routerLink]="['/galeria']" class="btn-go">
                        Ver más
                    </a>
                </div>
            </div>

            
            
            <div class="col-md-6 "> 
                <div class="bg-cta colabora">
                    <h4>
                        NUESTROS GANADORES 2023
                    </h4>
                    <a [routerLink]="['/galeria-2023']" class="btn-go">
                        Ver más
                    </a>
                </div>
            </div> -->

            <div class="col-lg-4 col-12">
                <div class="bg-cta premios">
                    <h4 class="align-text-bottom">
                        Premios
                    </h4>
                    <a [routerLink]="['/premios-sorteo-anahuac']" class="btn-go">
                        Conoce los premios
                    </a>
                </div>
            </div>




            <div class="col-lg-8">
                <div class="row">

                    <div class="col-md-6">
                        <div class="bg-cta colaborador">
                            <h4>
                                Premios de
                                colaborador
                            </h4>
                            <a [routerLink]="['/premios-para-colaboradores']" class="btn-go">
                                Ver más
                            </a>
                        </div>
                    </div>

                    <div class="col-md-6 pl-lg-0">
                        <div class="bg-cta blogs">
                            <h4>
                                BLOG
                            </h4>
                            <a href="https://gana.sorteoanahuac.mx/" target="_blank" class="btn-go">
                                Ver más
                            </a>
                        </div>
                    </div>

                    <div class="col-md-6 ">
                        <div class="bg-cta ganadores">
                            <h4>
                                NUESTROS GANADORES
                            </h4>
                            <a [routerLink]="['/galeria']" class="btn-go">
                                Ver más
                            </a>
                        </div>
                    </div>

                    <div class="col-md-6 pl-lg-0">
                        <div class="bg-cta colabora">
                            <h4>
                                COLABORA
                            </h4>
                            <a [routerLink]="['/formulario-registro-colaboradores']" class="btn-go">
                                Ver más
                            </a>
                        </div>
                    </div>

                </div>
            </div>



        </div>
    </div>
</section>



<!-- <section id="counters">
    <div class="container-fluid ">
        <div class="row">
            <div class="col-12">
                <h5>Siguiente sorteo:

                </h5>
            </div>
            <div class="col-lg-6 col-12 d-md-flex justify-content-center line mt-xl-0 mt-5 text-center ">
             
                    <span class="sorteo-opt ">
                        <a [routerLink]="['/sorteo-oportuno']">
                            tercer 
                            sorteo 
                            oportuno
                        </a>
                    </span>
                
                <div class="counter border-gradient-all text-center mt-xl-0 mt-4">
                    <div class="number-count">
                        <span>{{ dias }}</span>.<span>{{ horas }}</span>.<span>{{ minutos }}</span>.<span>{{ segundos }}</span>
                    </div>

                    <div class="labels text-center">
                        <span>DÍAS</span>
                        <span>HRS</span>
                        <span>MIN</span>
                        <span>SEG</span>
                    </div>
                </div>
               
            </div> -->

<!-- <div class="col-xl-4 col-lg-6 col-12 d-md-flex justify-content-center line mt-xl-0 mt-5 text-center">
              
                    <span class="sorteo-opt  sorteo-egr">
                        <a [routerLink]="['/sorteo-egresado-universidad-anahuac']"> 
                            SORTEO
                            <span class="d-lg-block">
                                Egresados
                            </span> 
                        </a>
                    </span>
               
                
                <div class="counter border-gradient-all text-center mt-xl-0 mt-4">
                    <div class="number-count">
                        <span>{{ dias_egr }}</span>.<span>{{ horas_egr }}</span>.<span>{{ minutos_egr }}</span>.<span>{{ segundos_egr }}</span>
                    </div>

                    <div class="labels text-center">
                        <span>DÍAS</span>
                        <span>HRS</span>
                        <span>MIN</span>
                        <span>SEG</span>
                    </div>
                </div>
               
            </div> 


            <div class="col-12 d-md-flex justify-content-center mt-xl-0 mt-5 text-center">
                
                    <span class="sorteo-opt  sorteo-mg">
                       <a href="https://sorteoanahuaccompra.sorteoanahuac.mx/compra-en-linea-boletos-sorteo-anahuac{{urlUtm}}" target="_blank">
                            SORTEO MAGNO 
                            <span class="d-lg-block">
                               2024
                            </span>  
                        </a>
                    </span>
              
                <div class="counter border-gradient-all text-center mt-xl-0 mt-4">
                    <div class="number-count">
                        <span>{{ day }}</span>.<span>{{ hours }}</span>.<span>{{ minutes }}</span>.<span>{{ seconds }}</span>
                        <span>¡PRÓXIMAMENTE!</span>
                    </div>

                    <div class="labels text-center">
                        <span>DÍAS</span>
                        <span>HRS</span>
                        <span>MIN</span>
                        <span>SEG</span>
                    </div>
                </div>
               
            </div>

           

        </div>
    </div>
</section> -->



<section id="parthers">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 text-center">
                <h2>nuestros aliados</h2>
                <a href="https://www.brezza.mx/" target="_blank">
                    <img src="/assets/images/sorteo2024/brezza.png" class="img-fluid" alt="Brezza">
                </a>

                <a href="https://www.bosquereal.com.mx/" target="_blank">
                    <img src="/assets/images/sorteo2024/bosque.png" class="img-fluid" alt="Boswue Real">
                </a>
                <a href="https://elorigen.mx/" target="_blank">
                    <img src="/assets/images/sorteo2024/origen.png" class="img-fluid" alt="El Origen">
                </a>
                <a href="https://www.kia.com/mx/main.html" target="_blank">
                    <img src="/assets/images/sorteo2024/logo-kia-2024.png" class="img-fluid" alt="KIA">
                </a>
                <a href="https://www.mini.com.mx/es_MX/home.html" target="_blank">
                    <img src="/assets/images/sorteo2024/mini.png" class="img-fluid" alt="Mini Cooper">
                </a>
                <a href="https://www.paypal.com/mx/home" target="_blank">
                    <img src="/assets/images/sorteo2024/paypal.png" class="img-fluid" alt="Paypal">
                </a>
                <a href="https://www.scotiabank.com.mx/" target="_blank">
                    <img src="/assets/images/sorteo2024/scotiabank.png" class="img-fluid" alt="Scotiabank">
                </a>
                <a href="https://www.santander.com.mx/" target="_blank">
                    <img src="/assets/images/sorteo2024/santander.png" class="img-fluid" alt="Santander">
                </a>

            </div>
        </div>
    </div>
</section>

<!-- Modal para compartir -->
<!-- <div class="modal fade pr-2" id="modal-share1" tabindex="-1" role="dialog" aria-labelledby="modal-share1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            
            <section class="share">
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center banner">
                            <h3>INVITACIÓN A <span>COMPRAR</span></h3>
                        </div>
                    </div>
                </div>
            </section>
            
            <section class="share-cta">
                <div class="container">
                    <div class="row">
                        <div class=" col-12 text-center">
                          
                            <p class="mb-4 mt-4"> Invita a tus amigos a comprar un boleto de sorteo Anáhuac, al hacerlo te da la oportunidad de ganar un premio como colaborador. </p>
                            <div class="content-btn ">
                                <a href="https://www.facebook.com/sharer/sharer.php?u={{link}}" target="_blank">
                                    <img src="/assets/images/pasa-la-voz/facebook.png" >
                                </a>
                                <a href="https://www.instagram.com/" target="_blank">
                                    <img src="/assets/images/pasa-la-voz/insta.png" >
                                </a>
                                <a href="https://api.whatsapp.com/send?text=Compra%20un%20boleto%20de%20Sorteo%20Anáhuac%20y%20gana%20fabulosos%20premios%0A¡Apoya%20a%20la%20educación!{{link}}" target="_blank">
                                    <img src="/assets/images/pasa-la-voz/whats.png">
                                </a>
                                <a href="https://twitter.com/intent/tweet?text=Compra%20un%20boleto%20de%20Sorteo%20Anáhuac%20y%20gana%20fabulosos%20premios%0A¡Apoya%20a%20la%20educación! {{link}}&" target="_blank">
                                    <img src="/assets/images/pasa-la-voz/tw.png" >
                                </a>
                            </div>
            
                            <p class="mt-4 mb-4 pb-0 copy">Puedes copiar este link para compartirlo donde quieras.</p>
                            <input id="link" value="{{link}}" readonly>
                            <div class="content">
                                <a (click)="clickbtn()" class="btn-d"> Copiar link</a>
                            </div>
                            <div *ngIf="select" class="alert alert-success mt-4 animate__animated  animate__fadeInUpBig" role="alert" >
                                Texto copiado.
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</div> -->


<!-- Modal send-->
<!-- <div class="modal fade" id="modal-msi" tabindex="-1" role="dialog" aria-labelledby="modal-msi" aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
           
            <div class="modal-body text-center no-padding">
                
               <a href="https://sorteoanahuaccompra.sorteoanahuac.mx/boletos-colaborador/NDIxMzI%3D">
                    <img src="/assets/images/sorteo2023/buenfin.jpg" alt="Buen fin" class="img-fluid">
               </a>

            </div>

        </div>
    </div>
</div> -->