
<section id="contact">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center my-auto">
                <!-- <h1 data-aos="flip-up" data-aos-duration="2000">CONTÁCTANOS</h1> -->
                <img src="/assets/images/sorteo2024/contacto.png" alt="" class="img-fluid">
            </div>
        </div>
    </div>
</section>



<section class="form-contact"  >
    <div class="container" data-aos="zoom-in" data-aos-duration="2000" data-aos-easing="ease" >
        <div class="row">

            <div class="col-md-12">
                <h2>
                    ¿En que te podemos ayudar? 
                </h2>

                <p class="subtitle">
                    Contáctanos para cualquier duda, información o sugerencia.
                </p>
            </div>

            <div class="col-lg-8 offsset-lg-4">

                <form #dataPostal="ngForm" (ngSubmit)="validateDataAll(dataPostal)" autocomplete="off">
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label for="Nombre">
                                Nombre
                            </label>
                            <input type="text" class="form-control" Nombre ngModel id="Nombre" name="Nombre" #Nombre="ngModel" [(ngModel)]="dataConact.Nombre" placeholder="" required>
                            <div *ngIf="Nombre.invalid " class="alert alert-danger">
                                Dato requerido
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <label for="Correo">
                                Correo
                            </label>
                            <input (input)="inputValidateEmail($event)" type="email" class="form-control" Email ngModel id="Email" name="Email" #Email="ngModel" [(ngModel)]="dataConact.Email" placeholder="" required>
                            <div *ngIf="(!valid_email )  " class="alert alert-danger">
                               Formato incorrecto
                            </div>
                            
                            
                        </div>
                        <div class="form-group col-md-12">
                            <label for="Telefono">
                                Teléfono
                            </label>
                            <input maxlength="15" (input)="inputValidator($event)" type="text" class="form-control" Telefono ngModel id="Telefono" name="Telefono" #Telefono="ngModel" [(ngModel)]="dataConact.Telefono" placeholder="" autocomplete="new-Telefono">
                        </div>
                        <div class="form-group col-md-12">
                            <label for="Ciudad">
                                Ciudad
                            </label>
                            <input type="text" class="form-control" Ciudad ngModel id="Ciudad" name="Ciudad" #Ciudad="ngModel" [(ngModel)]="dataConact.Ciudad" placeholder="" >
                        </div>
                        <div class="form-group col-md-12">
                            <label for="Mensaje">
                                Mensaje
                            </label>
                            <textarea  class="form-control men" Mensaje ngModel id="Mensaje" name="Mensaje" #Mensaje="ngModel" [(ngModel)]="dataConact.Mensaje" placeholder="" rows="10" class="w-100" style="padding:10px ;"></textarea>
                        </div>
                    </div>
                    <div class="text-center">
                        <button type="submit" class="btn-stack">
                            Enviar mensaje
                        </button>
                    </div>
                </form>


                <div class="info">
                    <p>
                        <span class="address-label">
                            Av. Universidad Anáhuac No.46 Colonia Lomas Anáhuac, Huixquilucan, Estado de México C.P. 52786
                        </span>
                    </p>
                    <p href="javascript:void(0)" (click)="scrollTo('title-contact')" style="cursor: pointer; color: #ff4700;">
                        <span class="tel-label">
                       
                                Encuentra tu número de atención en la siguiente lista
                          
                           
                        </span>
                    </p>
                    <p>
                        <span class="email-label">
                            sorteoanahuac@anahuac.mx
                        </span>
                    </p>
                    <p>
                        <span class="web-label">
                            www.sorteoanahuac.mx
                        </span>
                    </p>
                </div>

            </div>

        </div>
    </div>
</section>


<div class="modal fade" id="modalSend" tabindex="-1" role="dialog" aria-labelledby="modalSend" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content border-gradient-all ">

          <div class="modal-body text-center no-padding">
            <ng-container *ngIf="flag;">
                <div  class="lds-hourglass"></div>
            </ng-container>

            <ng-container *ngIf="mesagge;">
                <p>Tus datos han sido enviados.</p>
            </ng-container>

            
             
          </div>
          <div class="modal-footer ">
            <ng-container *ngIf="mesagge;">
                <a (click) = "reload()" class="btn-modal text-center" [routerLink]="['/contacto']" data-dismiss="modal">Aceptar</a>
            </ng-container>
          </div>
      </div>
    </div>
</div>



<section id="title-contact">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                    <h4>
                        ¿NECESITAS MÁS AYUDA?
                    </h4>

                    <p>
                        Si ninguna de tus dudas fue resuelta, contacta al Enlace Operativo de tu universidad.
                    </p>
            </div>
        </div>
    </div>
</section>

<section id="contacts">
    <div class="container">
        <div class="row">

            <div class="col-md-12 ">
                <div class="content-table text-center" data-aos="zoom-in" data-aos-easing="ease"  data-aos-duration="2000" >
                    <table class="table table-striped">
                        <thead class="text-center">
                          <tr class="text-center">
                            <th scope="col"> <span>Campus</span></th>
                            <th scope="col 2"><span>Enlace</span></th>
                            <th scope="col"><span>Correo(s)</span></th>
                            <th scope="col"><span>Teléfono</span></th>
                            <th scope="col"><span>Ext.</span></th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr class="text-center">
                                <td class="font-bold">Universidad Anáhuac México campus Norte</td>
                                <td class="font-bold">
                     
                                    Mireya Reynoso Mata
                                </td>
                                <td>
                                 
                         
                                    <a href="mailto:mireya.reynosoma@anahuac.mx" target="_blank">mireya.reynosoma@anahuac.mx</a>
        
                                </td>
                                <td><a href="tel:5553288096">55 5328 8096</a>	</td>
                                <td>7474</td>
                            </tr>

                            <tr class="text-center">
                                <td class="font-bold">Universidad Anáhuac México campus Sur</td>
                                <td class="font-bold">
                                    Martha Daniela Martinez García
                                </td>
                                <td>
                                    <a href="mailto:martha.martinezgar@anahuac.mx" target="_blank">martha.martinezgar@anahuac.mx</a><br>
                                </td>
                                <td><a href="tel:5556288800">55 5628 8800</a></td>
                                <td>764</td>
                            </tr>

                            <tr class="text-center">
                                <td class="font-bold">IEST Anáhuac</td>
                                <td class="font-bold">
                                    Daniela Salazar Rodríguez

                                </td>
                                <td>
                                    <a href="mailto:sorteo.anahuac@iest.edu.mx" target="_blank">sorteo.anahuac@iest.edu.mx</a><br>
                                </td>
                                <td><a href="tel:833 391 3964">833 391 3964		</a><br>WhatsApp : 833 391 3964</td>
                                <td>1278
                                </td>
                            </tr>


                            <tr class="text-center">
                                <td class="font-bold">Universidad Anáhuac Querétaro</td>
                                <td class="font-bold">
                                    Cynthia Gabriela Barraza Segovia
                                </td>
                                <td>
                                    <a href="mailto:cinthia.barraza@anahuac.mx" target="_blank">cinthia.barraza@anahuac.mx</a><br>
                                </td>
                                <td><a href="tel:442 2456742">442 245 6742</a><br>WhatsApp : 55 3438 5671</td>
                                <td>N/A
                                </td>
                            </tr>

                            <tr class="text-center">
                                <td class="font-bold">Universidad Anáhuac Puebla</td>
                                <td class="font-bold">
                                    Lizbeth López Guerrero Lezama	
                                </td>
                                <td>
                                    <a href="mailto:lizbeth.lopezgue@anahuac.mx" target="_blank">lizbeth.lopezgue@anahuac.mx</a><br>
                                </td>
                                <td><a href="tel:222 169 1069">222 169 1069</a><br>WhatsApp : 221 641 7737</td>
                                <td>306
                                </td>
                            </tr>

                            <tr class="text-center">
                                <td class="font-bold">Universidad Anáhuac Mayab</td>
                                <td class="font-bold">
                                    Alison Maertens Gaona 	
                                </td>
                                <td>
                                    <a href="mailto:alison.maertensga@anahuac.mx" target="_blank">alison.maertensga@anahuac.mx</a><br>
                                </td>
                                <td><a href="tel:999 942 4801">999 942 4801</a><br>WhatsApp : 999 900 9840	</td>
                                <td>1303
                                </td>
                            </tr>

                            <tr class="text-center">
                                <td class="font-bold">Universidad Anáhuac Oaxaca</td>
                                <td class="font-bold">
                                    Fabiola Donaji Calvo Pablo
                                </td>
                                <td>
                                    <a href="mailto:fabiola.calvopa@anahuac.mx" target="_blank">fabiola.calvopa@anahuac.mx</a><br>
                                </td>
                                <td><a href="tel:951 501 6250">951 501 6250	</a><br>WhatsApp : 951 228 6832	</td>
                                <td>1522
                                </td>
                            </tr>

<!-- 
                            <tr class="text-center">
                                <td class="font-bold">Universidad Anáhuac Cancún</td>
                                <td class="font-bold">
                                    Ingrid Áviles Castro	
                                </td>
                                <td>
                                    <a href="mailto:ingrid.avilescas@anahuac.mx" target="_blank">ingrid.avilescas@anahuac.mx</a><br>
                                </td>
                                <td><a href="tel:998 881 7750">998 881 7750		</a><br>WhatsApp : 55 1046 5022	</td>
                                <td>165
                                </td>
                            </tr> -->

                            <tr class="text-center">
                                <td class="font-bold"> Universidad Anáhuac Veracruz </td>
                                <td class="font-bold">
                                    Marco Antonio Zavaleta Conde
                                </td>
                                <td>
                                    <a href="mailto:marco.zavaletaco@anahuac.mx" target="_blank">marco.zavaletaco@anahuac.mx</a><br>
                                </td>
                                <td><a href="tel:228 819 1515">228 819 1515</a><br>WhatsApp : 228 179 4441	</td>
                                <td>3098
                                </td>
                            </tr>

<!--                             
                            <tr class="text-center">
                                <td class="font-bold">Campus Córdoba Orizaba</td>
                                <td class="font-bold">
                                    Marco Antonio Zavaleta Conde
                                </td>
                                <td>
                                    <a href="mailto:marco.zavaletaco@anahuac.mx" target="_blank">marco.zavaletaco@anahuac.mx</a><br>
                                </td>
                                <td><a href="tel:228 819 1515">228 819 1515</a><br>WhatsApp : 228 179 4441	</td>
                                <td>3098
                                </td>
                            </tr> -->





                        </tbody>
                      </table>
                </div>
                

            </div>
        </div>
    </div>
</section>











<div class="modal fade pr-0" id="modalError" tabindex="-1" role="dialog" aria-labelledby="modalError" aria-hidden="true" >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content border-gradient-all ">
          <div class="modal-body text-center no-padding">
                <p>Favor de revisar, hay datos incompletos.</p>
          </div>
          <div class="modal-footer border-0 justify-content-center text-center">
                <a class="btn-modal" data-dismiss="modal">Aceptar</a>
          </div>
      </div>
    </div>
</div>
